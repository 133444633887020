import {cnb} from 'cnbuilder';
import {useState, useEffect, useRef, useCallback} from 'react';
import {useQuery} from 'react-query';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import notify from '@livongo/pulse/ui/Notification';
import Button from '@livongo/pulse/ui/Button';
import Label from '@livongo/pulse/ui/Label';
import Loader from '@livongo/pulse/ui/Loader';
import Modal from '@livongo/pulse/ui/Modal';
import Panel from '@livongo/pulse/ui/Panel';
import Tag from '@livongo/pulse/ui/Tag';
import Toggle from '@livongo/pulse/ui/Toggle';
import TextInput from '@livongo/pulse/ui/TextInput';
import IconArrowDownDefault from '@livongo/pulse/icons/arrow-down-default.svg';
import IconArrowUpDefault from '@livongo/pulse/icons/arrow-up-default.svg';
import Select from '@livongo/pulse/ui/Select';
import ZipInput from '@livongo/pulse/ui/ZipInput';
import StateSelect from '@livongo/pulse/ui/StateSelect';
import MemberAPI from 'common/member-api';
import FulfillmentAPI from 'common/fulfillment-api';
import Config from '../common/config';
import CampaignAPI from './campaign-api';
import CampaignUtils from './campaign-utils';
import css from './CampaignItem.scss';

const SUBMIT_REORDER = 'SUBMIT_REORDER';
const TOGGLE_ENROLLMENT = 'TOGGLE_ENROLLMENT';
const CREATE_ORDER_FAILED = 'CREATE_ORDER_FAILED';
const ORDER_PENDING = 'ORDER_PENDING';
const ORDER_SUBMITTED = 'ORDER_SUBMITTED';
const ACTIVE = 'ACTIVE';
const ZIP_CODE = 'zipcode';
const PHONE_NUMBER = 'phoneNumber';
const ADDRESS_LINE1 = 'addressLine1';
const ADDRESS_LINE2 = 'addressLine2';
const COUNTRY = 'country';
const STATE = 'state';
const CITY = 'city';
const GENDER = 'gender';

const FIELD_LABELS = {
    [PHONE_NUMBER]: 'Phone Number',
    [ADDRESS_LINE1]: 'Address Line1',
    [ADDRESS_LINE2]: 'Address Line2',
    [COUNTRY]: 'Country',
    [STATE]: 'State',
    [CITY]: 'City',
    [GENDER]: 'Gender',
    [ZIP_CODE]: 'Zip code',
};

function renderTrigger({
    name,
    toggleKey,
    isChecked,
    isLoading,
    isExpanded,
    errorMessage,
    triggerProps,
    currentStatus,
    showToggleButton,
    onToggleChange,
    campaignStatus,
}) {
    const {onClick, className, ...otherProps} = triggerProps || {};
    const type =
        CampaignUtils.SHIPMENT_STATUS_TYPE[currentStatus] || 'highlight';

    return (
        <div {...otherProps} className={className}>
            <div className={css.trigger}>
                <div className={css.column}>
                    <span className={css.name}>{name}</span>
                    <Toggle
                        id="optIn"
                        name="optIn"
                        key={toggleKey}
                        disabled={
                            isLoading ||
                            campaignStatus?.trim()?.toUpperCase() !== ACTIVE
                        }
                        defaultChecked={isChecked}
                        classNameRoot={css.toggle}
                        label={<Label>Opt-in:</Label>}
                        onChange={onToggleChange}
                    />
                </div>
                <div className={css.column}>
                    <div>
                        <span
                            className={cnb(css.enrollment, {
                                [css.enrolled]: isChecked,
                            })}
                        >
                            {isChecked ? 'Yes' : 'No'}
                        </span>
                        {currentStatus && (
                            <Tag
                                variant={type}
                                {...(type === 'highlight' && {
                                    className: css.highlight,
                                })}
                            >
                                {currentStatus}
                            </Tag>
                        )}
                    </div>
                    {showToggleButton &&
                        (isExpanded ? (
                            <Button
                                icon={<IconArrowUpDefault />}
                                onClick={onClick}
                            >
                                Less
                            </Button>
                        ) : (
                            <Button
                                icon={<IconArrowDownDefault />}
                                onClick={onClick}
                            >
                                More
                            </Button>
                        ))}
                </div>
            </div>
            {errorMessage && (
                <div className={css.errorMessage}>
                    <p>{errorMessage}</p>
                </div>
            )}
        </div>
    );
}

const CampaignItem = ({
    id,
    pid,
    name,
    orderId,
    orders,
    defaultValue,
    latestStatus,
    errorMessage,
    trackingNumber,
    campaignStatus,
}) => {
    const hasMounted = useRef(false);
    const [toggleKey, setToggleKey] = useState(new Date().getTime());
    const [currentStatus, setCurrentStatus] = useState(latestStatus);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isChecked, setIsChecked] = useState(defaultValue);
    const [showModal, setShowModal] = useState(false);
    const [modalTrigger, setModalTrigger] = useState(null);
    const [shippingInfo, setShippingInfo] = useState(null);
    const [shippingInfoError, setShippingInfoError] = useState({
        gender: null,
        addressLine1: null,
        city: null,
        state: null,
        country: null,
        zipcode: null,
    });
    const canExpand =
        trackingNumber ||
        !isEmpty(orders) ||
        [CREATE_ORDER_FAILED, ORDER_PENDING].includes(currentStatus);

    const {isFetching: isLoadingEnrollment, refetch: toggleEnrollment} =
        useQuery(
            ['enrollCampaign', {campaignId: id, pid, isEnrolling: isChecked}],
            CampaignAPI.enroll,
            {
                retry: false,
                enabled: false,
            }
        );
    const {
        isFetched,
        data: user,
        isFetching: isLoadingMemberInfo,
        refetch: getMemberInfo,
    } = useQuery(['memberInfo', pid], MemberAPI.getPersonalInfo, {
        retry: false,
        enabled: false,
    });
    const {isFetching: isReorderLoading, refetch: reorderTestKit} = useQuery(
        ['reorderTestKit', {userId: pid, campaignId: id}],
        FulfillmentAPI.reorderTestKit,
        {
            retry: false,
            enabled: false,
        }
    );

    const {isFetching: isPendingReorderLoading, refetch: reorderPendingKit} =
        useQuery(
            [
                'reorderPendingKit',
                {orderId, status: ORDER_SUBMITTED, shippingInfo},
            ],
            FulfillmentAPI.reorderPendingKit,
            {
                retry: false,
                enabled: false,
            }
        );

    const onPanelToggle = ({isExpanded: expanded}) => {
        setIsExpanded(expanded);
    };
    const onModalToggle = () => {
        // If modal is dismissed, turn the toggle off
        if (showModal && modalTrigger === TOGGLE_ENROLLMENT) {
            setIsChecked(false);
            setToggleKey(new Date().getTime());
        }

        setShowModal(show => !show);
    };
    const onToggleEnrollment = useCallback(async () => {
        try {
            const {error, isError} = await toggleEnrollment();

            if (isError) {
                throw new Error(error);
            }

            setShowModal(false);
            setModalTrigger(null);

            notify({
                type: 'success',
                title: `Member ${
                    isChecked ? 'opted in' : 'opted out'
                } successfully`,
                message: `Member ${pid} is now ${
                    isChecked ? 'opted in into' : 'opted out from'
                } ${name}`,
            });
        } catch (error) {
            notify({
                type: 'critical',
                title: 'Error',
                message: `We couldn't update this member's enrollment status at this time. Please try again later.`,
            });
        }
    }, [isChecked, name, pid, toggleEnrollment]);

    const hasShippingInfoError = () => {
        const {
            gender,
            addressLine1,
            phoneNumber,
            city,
            state,
            country,
            zipcode,
        } = shippingInfoError;

        return (
            !isEmpty(gender) ||
            !isEmpty(addressLine1) ||
            !isEmpty(phoneNumber) ||
            !isEmpty(city) ||
            !isEmpty(state) ||
            !isEmpty(country) ||
            !isEmpty(zipcode)
        );
    };

    const onSubmitReorder = async () => {
        try {
            if (currentStatus === ORDER_PENDING && hasShippingInfoError()) {
                notify({
                    type: 'critical',
                    title: 'Error',
                    message: `Please enter a valid address`,
                });

                return;
            }

            const {data, error, isError} =
                currentStatus === ORDER_PENDING
                    ? await reorderPendingKit()
                    : await reorderTestKit();

            if (isError) {
                const {
                    data: {details} = {
                        details: `We couldn't complete an order at this time. Please try again later.`,
                    },
                } = error;

                throw new Error(details);
            }

            setShowModal(false);
            setModalTrigger(null);
            setCurrentStatus(data?.status);

            notify({
                type: 'success',
                title: 'Order Submitted',
                message: 'Test kit order was submited successfully.',
            });
        } catch (error) {
            notify({
                type: 'critical',
                title: 'Error',
                message: String(error),
            });
        }
    };
    const onToggleChange = ({target: {checked}}) => {
        if (checked) {
            setShowModal(true);
            setModalTrigger(TOGGLE_ENROLLMENT);
        }

        setIsChecked(checked);
    };
    const onReorderClick = () => {
        onModalToggle();
        setModalTrigger(SUBMIT_REORDER);
    };
    const MODAL_ACTIONS = {
        [SUBMIT_REORDER]: onSubmitReorder,
        [TOGGLE_ENROLLMENT]: onToggleEnrollment,
    };

    useEffect(() => {
        if (user) {
            const {
                address = null,
                gender = 'Male',
                firstName,
                lastName,
                phones,
            } = user;
            let phone = find(
                phones,
                ph => ph.phoneType === Config.PHONE_TYPE.mobile
            );

            if (!phone) {
                phone = find(
                    phones,
                    ph => ph.phoneType === Config.PHONE_TYPE.home
                );
            }

            setShippingInfo({
                ...address,
                ...{
                    gender,
                    firstName,
                    lastName,
                    phoneNumber: phone ? phone.number : null,
                },
            });
        }
    }, [user]);

    useEffect(() => {
        if (showModal) {
            getMemberInfo();
        }
    }, [showModal, getMemberInfo]);

    useEffect(() => {
        // Updates enrollment  when toggle changes to opt out and a Modal isn't being dismissed
        if (hasMounted.current && !isChecked && !modalTrigger) {
            onToggleEnrollment();
        }

        if (!hasMounted.current) {
            hasMounted.current = true;
        }
    }, [hasMounted, isChecked, modalTrigger, onToggleEnrollment]);

    const addressErrorChecks = ({type, value}) => {
        let addrError = null;

        if (isEmpty(value) || (!isEmpty(value) && value.trim() === '')) {
            addrError = `${FIELD_LABELS[type]} is required`;
        }

        if (type === ZIP_CODE) {
            if (
                !Config.ZIPCODE_SHORT_REGEX.test(value) &&
                !Config.ZIPCODE_EXTENDED_REGEX.test(value)
            ) {
                addrError = `Enter a valid ${FIELD_LABELS[type]}`;
            }
        }

        if (type === PHONE_NUMBER) {
            if (!isEmpty(value) && !Config.PHONE_REGEX.test(value)) {
                addrError = `Enter a valid ${FIELD_LABELS[type]}`;
            }
        }

        return addrError;
    };

    const updateAddress = ({type, value}) => {
        const updated = {
            [type]:
                type === PHONE_NUMBER
                    ? value
                        ? value.replaceAll('-', '')
                        : null
                    : value,
        };

        setShippingInfoError({
            ...shippingInfoError,
            ...{[type]: addressErrorChecks({type, value})},
        });
        setShippingInfo({...shippingInfo, ...updated});
    };

    const renderAddress = () => {
        if (shippingInfo) {
            const {
                gender,
                addressLine1,
                addressLine2,
                phoneNumber,
                city,
                state,
                country,
                zipcode,
            } = shippingInfo;
            const {
                gender: genderErr,
                addressLine1: addressLine1Err,
                city: cityErr,
                phoneNumber: phoneNumberErr,
                state: stateErr,
                country: countryErr,
                zipcode: zipcodeErr,
            } = shippingInfoError;

            if (currentStatus === ORDER_PENDING) {
                const genderValue = find(
                    Config.GENDERS,
                    g => g.value === gender
                )?.value;

                return (
                    <address>
                        <Select
                            id={GENDER}
                            classNameRoot={css.formField}
                            items={Config.GENDERS}
                            label={<Label>{FIELD_LABELS[GENDER]}*</Label>}
                            defaultValue={genderValue}
                            onChange={e => {
                                updateAddress({
                                    type: GENDER,
                                    value: e ? e.value : null,
                                });
                            }}
                        />
                        {genderErr && (
                            <Label className={css.errorLabel}>
                                {genderErr}
                            </Label>
                        )}

                        <TextInput
                            id={ADDRESS_LINE1}
                            label={
                                <Label>{FIELD_LABELS[ADDRESS_LINE1]}*</Label>
                            }
                            defaultValue={addressLine1}
                            onChange={e =>
                                updateAddress({
                                    type: ADDRESS_LINE1,
                                    value: e.target.value,
                                })
                            }
                        />
                        {addressLine1Err && (
                            <Label className={css.errorLabel}>
                                {addressLine1Err}
                            </Label>
                        )}

                        <TextInput
                            id={ADDRESS_LINE2}
                            label={<Label>{FIELD_LABELS[ADDRESS_LINE2]}</Label>}
                            defaultValue={addressLine2}
                            onChange={e =>
                                updateAddress({
                                    type: ADDRESS_LINE2,
                                    value: e.target.value,
                                })
                            }
                        />

                        <TextInput
                            id={PHONE_NUMBER}
                            name={PHONE_NUMBER}
                            label={<Label>{FIELD_LABELS[PHONE_NUMBER]}*</Label>}
                            mask="999-999-9999"
                            defaultValue={phoneNumber ? phoneNumber : null}
                            onChange={e =>
                                updateAddress({
                                    type: PHONE_NUMBER,
                                    value: e.target.value,
                                })
                            }
                        />
                        {phoneNumberErr && (
                            <Label className={css.errorLabel}>
                                {phoneNumberErr}
                            </Label>
                        )}

                        <TextInput
                            id={CITY}
                            label={<Label>{FIELD_LABELS[CITY]}*</Label>}
                            defaultValue={city}
                            onChange={e =>
                                updateAddress({
                                    type: CITY,
                                    value: e.target.value,
                                })
                            }
                        />
                        {cityErr && (
                            <Label className={css.errorLabel}>{cityErr}</Label>
                        )}

                        <StateSelect
                            id={STATE}
                            classNameRoot={css.formField}
                            label={<Label>{FIELD_LABELS[STATE]}*</Label>}
                            defaultValue={state}
                            onChange={e => {
                                updateAddress({
                                    type: STATE,
                                    value: e ? e.value : null,
                                });
                            }}
                            includeMilitary={false}
                            includeUSTerritories={false}
                        />
                        {stateErr && (
                            <Label className={css.errorLabel}>{stateErr}</Label>
                        )}

                        <TextInput
                            id={COUNTRY}
                            label={<Label>{FIELD_LABELS[COUNTRY]}*</Label>}
                            defaultValue={country}
                            disabled
                            onChange={e =>
                                updateAddress({
                                    type: COUNTRY,
                                    value: e.target.value,
                                })
                            }
                        />
                        {countryErr && (
                            <Label className={css.errorLabel}>
                                {countryErr}
                            </Label>
                        )}

                        <ZipInput
                            id={ZIP_CODE}
                            defaultValue={zipcode}
                            label={
                                <Label required>
                                    {FIELD_LABELS[ZIP_CODE]}*
                                </Label>
                            }
                            onKeyUp={e =>
                                updateAddress({
                                    type: ZIP_CODE,
                                    value: e.target.value,
                                })
                            }
                        />
                        {zipcodeErr && (
                            <Label className={css.errorLabel}>
                                *{zipcodeErr}
                            </Label>
                        )}
                    </address>
                );
            } else {
                return (
                    <address>
                        <p className={css.addressLine}>{addressLine1}</p>
                        <p>
                            {city}, {state}, {country}, {zipcode}
                        </p>
                    </address>
                );
            }
        }

        return null;
    };

    return (
        <>
            {canExpand ? (
                <Panel
                    classNameTrigger={css.triggerContainer}
                    customRender={({trigger: triggerProps}) => {
                        return renderTrigger({
                            name,
                            toggleKey,
                            isChecked,
                            isExpanded,
                            errorMessage,
                            triggerProps,
                            currentStatus,
                            onToggleChange,
                            showToggleButton: canExpand,
                            isLoading: isLoadingEnrollment,
                            campaignStatus,
                        });
                    }}
                    content={
                        <div className={css.content}>
                            <div className={css.tracking}>
                                <span>
                                    Tracking number:{' '}
                                    <span className={css.number}>
                                        {trackingNumber || 'Not available yet'}
                                    </span>
                                </span>
                                {[CREATE_ORDER_FAILED, ORDER_PENDING].includes(
                                    currentStatus
                                ) && (
                                    <>
                                        <div className={css.divider} />
                                        <Button
                                            size="sm"
                                            variant="primary"
                                            className={css.reorder}
                                            onClick={onReorderClick}
                                        >
                                            {currentStatus === ORDER_PENDING
                                                ? 'Resubmit'
                                                : 'Reorder'}
                                        </Button>
                                    </>
                                )}
                            </div>
                            {orders.map(({timestamp, status}, index) => (
                                <div key={index} className={css.progress}>
                                    <span className={css.date}>
                                        {format(new Date(timestamp), 'PPpp')}
                                    </span>
                                    <span>{status}</span>
                                </div>
                            ))}
                        </div>
                    }
                    onToggle={onPanelToggle}
                />
            ) : (
                <div className={css.triggerContainer}>
                    {renderTrigger({
                        name,
                        toggleKey,
                        isChecked,
                        isExpanded,
                        currentStatus,
                        onToggleChange,
                        showToggleButton: canExpand,
                        campaignStatus,
                    })}
                </div>
            )}

            <Modal
                isOpen={showModal}
                title="Address Confirmation"
                primaryAction={{
                    children: 'Submit',
                    disabled: isReorderLoading || isPendingReorderLoading,
                    loading: isReorderLoading || isPendingReorderLoading,
                    onClick: MODAL_ACTIONS[modalTrigger],
                }}
                secondaryAction={{children: 'Cancel', onClick: onModalToggle}}
                onRequestClose={onModalToggle}
            >
                {!isFetched || isLoadingMemberInfo ? (
                    <Loader />
                ) : (
                    <>
                        <p>
                            In order to ensure the order can be placed without
                            errors, and the kit be delivered to the correct
                            address, confirm and if necessary update the
                            member&apos;s address.
                        </p>
                        <p>
                            <strong>
                                {user?.firstName} {user?.lastName}
                            </strong>
                        </p>
                        {user?.address && renderAddress()}
                    </>
                )}
            </Modal>
        </>
    );
};

CampaignItem.propTypes = {
    /** Campaign unique identification. */
    id: PropTypes.string.isRequired,
    /** Member unique public identification. */
    pid: PropTypes.string.isRequired,
    /** The name of the campaign. */
    name: PropTypes.string.isRequired,
    /** The default enrollment status, if a member is opted in or not. */
    defaultValue: PropTypes.bool.isRequired,
    /** The cuurent shipping status */
    latestStatus: PropTypes.string,
    errorMessage: PropTypes.string,
    trackingNumber: PropTypes.string,
    orderId: PropTypes.string.isRequired,
    orders: PropTypes.arrayOf(
        PropTypes.shape({
            status: PropTypes.string,
            timestamp: PropTypes.string,
        })
    ),
    campaignStatus: PropTypes.string,
};

export default CampaignItem;
