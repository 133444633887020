import {cnb} from 'cnbuilder';
import PropTypes from 'prop-types';
import Button from '@livongo/pulse/ui/Button';
import css from './PotentialMatch.scss';

const ActionCell = ({
    row: {values},
    cell: {
        value: {hasAction, hasGroupMatched, hasItemMatched, onActionClick},
    },
}) => {
    return (
        <Button
            disabled={hasGroupMatched}
            className={cnb({[css.actionHidden]: !hasAction})}
            onClick={() => onActionClick(values)}
        >
            {hasItemMatched ? 'Matched' : 'Match'}
        </Button>
    );
};

ActionCell.propTypes = {
    row: PropTypes.shape({
        values: PropTypes.object,
    }),
    cell: PropTypes.shape({
        value: PropTypes.shape({
            hasAction: PropTypes.bool,
            hasItemMatched: PropTypes.bool,
            hasGroupMatched: PropTypes.bool,
            onActionClick: PropTypes.func,
        }),
    }).isRequired,
};

export default ActionCell;
