import APIUtils from '@livongo/utilities/system/api';
import Config from './config';

const MemberAPI = {
    getAccessToken: ({queryKey}) => {
        const [, pid] = queryKey;

        return APIUtils.post(`${Config.PLATFORM_API_URL}v1/admin/auth/user`, {
            name: pid,
            scope: 'WriteUser',
        }).then(({data}) => {
            const {access_token: accessToken, expires_in: expires} = data;

            Config.set({MEMBER_ACCESS_TOKEN: accessToken});

            return {...data, expiresAt: expires * 1000 - 60000}; // 60,000ms = 1 minute
        });
    },

    getPersonalInfo: ({queryKey}) => {
        const [, pid] = queryKey;

        return APIUtils.get(
            `${Config.PLATFORM_API_URL}v1/users/${pid}/info/personal`
        ).then(({data}) => data);
    },
};

export default MemberAPI;
