import isEmpty from 'lodash/isEmpty';

const CampaignUtils = {
    SHIPMENT_STATUS_TYPE: {
        ORDER_PENDING: 'warning',
        ORDER_SUBMITTED: 'highlight',
        ORDER_CREATED: 'highlight',
        ORDER_REPLACED: 'highlight',
        ORDER_SHIPPED: 'highlight',
        SAMPLE_COLLECTED: 'highlight',
        SAMPLE_AT_LAB: 'highlight',
        RESULTS_RECEIVED: 'success',
        RESULTS_AVAILABLE: 'success',
        RESULTS_INVALID: 'critical',
        CREATE_ORDER_FAILED: 'critical',
        ORDER_CANCELLED: 'critical',
        LETTER_SENT_TO_PATIENT: 'highlight',
        LETTER_SENT_TO_PCP: 'highlight',
    },

    formatData(orders) {
        const [latest] = orders;
        const {
            id,
            status,
            errorMessage,
            inboundTrackingNumber: inbound,
            outboundTrackingNumber: outbound,
        } = latest || {};

        const history = orders.reduce((collection, {statusHistory}) => {
            if (!isEmpty(statusHistory)) {
                collection.push(...statusHistory);
            }

            return collection;
        }, []);

        return {
            orderId: id,
            status,
            history,
            errorMessage,
            trackingNumber: inbound || outbound,
        };
    },
};

export default CampaignUtils;
