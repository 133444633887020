import './index.scss';
import forEach from 'lodash/map';
import {render} from 'react-dom';
import isUndefined from 'lodash/isUndefined';
import {QueryClient, QueryClientProvider} from 'react-query';
import APIUtils from '@livongo/utilities/system/api';
import PulseApp, {STATUSES} from '@livongo/pulse/ui/App';
import Config from 'common/config';
import CampaignCard from '../CampaignCard';
import PotentialMatch from '../PotentialMatch';

const queryClient = new QueryClient();
const COMPONENTS = {
    CAMPAIGN_CARD: CampaignCard,
    POTENTIAL_MATCH_LIST: PotentialMatch,
};

const AtmosphereUtils = {
    init: options => {
        forEach(options, (value, key) => {
            Config.set({[key]: value});
        });
    },

    render: ({accessToken, targetId, componentId, props}) => {
        const Component = COMPONENTS[componentId];

        [accessToken, targetId, componentId].forEach(param => {
            if (isUndefined(param)) {
                throw new Error(
                    'Error: Check if accessToken, targetId, and componentId are being passed to the function AtmosphereUtils.render'
                );
            }
        });

        APIUtils.init({
            baseUrl: Config.MICROSERVICE_API_URL,
            additionalHeaders: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        });

        render(
            <PulseApp
                render={({data, status}) => {
                    switch (status) {
                        case STATUSES.INITIALIZED:
                            return (
                                <QueryClientProvider client={queryClient}>
                                    <Component {...props} />
                                </QueryClientProvider>
                            );
                    }
                }}
            />,
            document.getElementById(targetId)
        );
    },
};

window.AtmosphereUtils = AtmosphereUtils;

export default AtmosphereUtils;
