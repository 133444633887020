import isNull from 'lodash/isNull';
import {useQuery, useMutation} from 'react-query';
import {useState, useEffect} from 'react';
import Button from '@livongo/pulse/ui/Button';
import Label from '@livongo/pulse/ui/Label';
import Table from '@livongo/pulse/ui/Table';
import Modal from '@livongo/pulse/ui/Modal';
import Loader from '@livongo/pulse/ui/Loader';
import notify from '@livongo/pulse/ui/Notification';
import AdvancedSelect from '@livongo/pulse/ui/AdvancedSelect';
import ApiError from '../ApiError';
import Card from '../Card';
import DefaultCell from './DefaultCell';
import ActionCell from './ActionCell';
import PotentialMatchAPI from './potential-match-api';
import PotentialMatchUtils from './potential-match-utils';
import css from './PotentialMatch.scss';

const DEFAULT_COUNT = 10;

const QUERY_OPTIONS = {
    retry: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
};

// Interate through the object PROPERTIES
const TABLE_COLUMNS = [
    ...Object.entries(PotentialMatchUtils.PROPERTIES).map(([key, value]) => ({
        accessor: key,
        Header: value,
        Cell: DefaultCell,
    })),
    {accessor: 'action', Header: '', Cell: ActionCell},
];

const PotentialMatch = () => {
    const [filter, setFilter] = useState({count: DEFAULT_COUNT, offset: 0});
    const [formattedRecords, setFormattedRecords] = useState(null);
    const [selectedMatch, setSelectedMatch] = useState(null);

    const {
        isFetching,
        data: recordsData,
        isError: hasAPIError,
    } = useQuery(
        ['fetchRecords', filter],
        PotentialMatchAPI.fetchRecords,
        QUERY_OPTIONS
    );

    const {data: clients} = useQuery(
        ['fetchClients'],
        PotentialMatchAPI.fetchClients,
        QUERY_OPTIONS
    );

    const matchMutation = useMutation(() => {
        try {
            return PotentialMatchAPI.match(selectedMatch);
        } catch (error) {
            // TODO: Handle matching API errors
        }
    });

    const onClientChange = value => {
        setFilter(params => ({
            ...params,
            clientCodes: [value],
            count: DEFAULT_COUNT,
            offset: 0,
        }));
    };

    const onClearClick = () => {
        setFilter(params => ({
            ...params,
            clientCodes: [],
            count: DEFAULT_COUNT,
            offset: 0,
        }));
    };

    const onMatchClick = data => {
        setSelectedMatch(data);
    };

    const onMatchSuccess = () => {
        const {
            action: {parentPID, parentUUID},
        } = selectedMatch;

        setFormattedRecords(currentRecords =>
            currentRecords.map(item => {
                const {className, pid, action} = item;
                const hasItemMatched = action.parentUUID === parentUUID;
                const hasGroupMatched =
                    pid?.value === parentPID || hasItemMatched;

                return {
                    ...item,
                    ...(hasGroupMatched && {
                        action: {
                            ...action,
                            hasItemMatched,
                            hasGroupMatched,
                        },
                        className: `${className} ${css.matched}`,
                    }),
                };
            })
        );
        setSelectedMatch(null);
    };

    const onModalClose = () => {
        setSelectedMatch(null);
    };

    const onConfirmClick = () => {
        matchMutation.mutate(null, {
            onSuccess: () => {
                notify({
                    type: 'success',
                    title: 'Success',
                    message:
                        'The selected record has been matched successfully.',
                });
                onMatchSuccess();
            },
            onError: () => {
                notify({
                    type: 'critical',
                    title: 'Error',
                    message:
                        'There was an error matching the selected record. Please try again later.',
                });
            },
        });
    };

    const onLoadMoreClick = () => {
        const {offset} = filter;
        const increment =
            offset < recordsData?.pagination?.total ? DEFAULT_COUNT : 0;
        const newOffset = offset + increment;

        setFilter(params => ({
            ...params,
            offset: newOffset,
        }));
    };

    useEffect(() => {
        const {data, pagination} = recordsData || {};

        if (data && pagination) {
            const formattedTableData = PotentialMatchUtils.formatData({
                data,
                onActionClick: onMatchClick,
            });

            setFormattedRecords(prev =>
                filter.offset === 0
                    ? [...formattedTableData]
                    : [...prev, ...formattedTableData]
            );
        }
    }, [recordsData, filter.offset]);

    if (hasAPIError) {
        return (
            <Card>
                <ApiError />
            </Card>
        );
    }

    return (
        <>
            <div className={css.filterSearch}>
                <AdvancedSelect
                    id="advClientSelector"
                    classNameRoot={css.formField}
                    items={clients?.map(value => ({value, label: value}))}
                    input={{
                        label: <Label>Client</Label>,
                    }}
                    onItemSelect={onClientChange}
                    onClear={onClearClick}
                />
            </div>
            {!formattedRecords || (isFetching && filter.offset === 0) ? (
                <Loader />
            ) : (
                <>
                    <div className={css.tableContainer}>
                        <Table
                            highlightOnHover
                            classNameBody={css.body}
                            classNameTable={css.table}
                            classNameNoData={css.noDataDiv}
                            columns={TABLE_COLUMNS}
                            data={formattedRecords}
                        />
                    </div>
                    <div className={css.moreContainer}>
                        <Button
                            variant="primary"
                            loading={isFetching}
                            disabled={
                                isFetching ||
                                filter.offset + filter.count >=
                                    recordsData?.pagination.total
                            }
                            onClick={onLoadMoreClick}
                        >
                            Load More
                        </Button>
                    </div>
                </>
            )}
            {selectedMatch && (
                <Modal
                    title="Match User"
                    isOpen={!isNull(selectedMatch)}
                    classNameRoot={css.rootModal}
                    primaryAction={{
                        children: 'Confirm',
                        type: 'submit',
                        variant: 'primary',
                        onClick: onConfirmClick,
                    }}
                    secondaryAction={{
                        children: 'Cancel',
                        type: 'submit',
                        variant: 'secondary',
                        onClick: onModalClose,
                    }}
                    onRequestClose={onModalClose}
                >
                    <p className={css.highlight}>
                        Are you sure you want to match user{' '}
                        {selectedMatch.pid.value}?
                    </p>
                    <p>
                        Member:{' '}
                        <span className={css.highlight}>
                            {selectedMatch.action.parentPID}
                        </span>{' '}
                        will be matched to an eligibility record with Tracking
                        ID:{' '}
                        <span className={css.highlight}>
                            {selectedMatch.trackingId.value}
                        </span>
                        .
                    </p>
                </Modal>
            )}
        </>
    );
};

export default PotentialMatch;
