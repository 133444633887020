import {cnb} from 'cnbuilder';
import PropTypes from 'prop-types';
import IconInfo from '@livongo/pulse/icons/info-active.svg';
import css from './PotentialMatch.scss';

const DefaultCell = ({
    cell: {
        value: {value, hasError},
    },
}) => {
    return (
        <div
            className={cnb(css.cell, {
                [css.cellError]: hasError,
            })}
        >
            {hasError && <IconInfo className={css.infoIcon} />}
            {value}
        </div>
    );
};

DefaultCell.propTypes = {
    cell: PropTypes.shape({
        value: PropTypes.shape({
            value: PropTypes.string,
            hasError: PropTypes.bool,
        }),
    }).isRequired,
};

export default DefaultCell;
