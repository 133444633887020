import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {useQuery} from 'react-query';
import {useState, useEffect} from 'react';
import isUndefined from 'lodash/isUndefined';
import Loader from '@livongo/pulse/ui/Loader';
import HorizontalRule from '@livongo/pulse/ui/HorizontalRule';
import MemberAPI from 'common/member-api';
import ApiError from '../ApiError';
import Card from '../Card';
import CampaignAPI from './campaign-api';
import CampaignUtils from './campaign-utils';
import CampaignItem from './CampaignItem';
import css from './CampaignCard.scss';

const CampaignCard = ({pid}) => {
    const [refetchAt, setRefetchAt] = useState(false);
    const {
        data: auth,
        isError: hasTokenError,
        isFetching: isLoadingToken,
    } = useQuery(['accessToken', pid], MemberAPI.getAccessToken, {
        retry: false,
        refetchInterval: refetchAt,
        refetchOnWindowFocus: false,
    });
    const {
        isFetched,
        data: campaigns,
        isError: hasCampaignsError,
        isFetching: isLoadingCampaigns,
    } = useQuery(['campaigns', pid], CampaignAPI.getCampaigns, {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: !isUndefined(auth?.access_token),
    });

    useEffect(() => {
        const {expiresAt} = auth || {};

        if (expiresAt) {
            setRefetchAt(expiresAt);
        }
    }, [auth]);

    if (hasTokenError || hasCampaignsError) {
        return (
            <Card>
                <ApiError />
            </Card>
        );
    }

    if (!isFetched || isLoadingToken || isLoadingCampaigns) {
        return (
            <Card>
                <Loader />
            </Card>
        );
    }

    return (
        <Card className={css.root}>
            <div className={css.header}>
                <h2 className={css.heading}>Campaigns</h2>
            </div>
            <HorizontalRule />
            <div className={css.tableHeader}>
                <span className={css.column}>Campaign Title</span>
                <span className={css.column}>Status</span>
            </div>
            {isEmpty(campaigns) ? (
                <p className={css.nullState}>
                    No campaigns for this member yet.
                </p>
            ) : (
                campaigns.map(
                    ({
                        id,
                        name,
                        isEnrolled,
                        orders,
                        status: campaignStatus,
                    }) => {
                        const {
                            orderId,
                            status,
                            history,
                            errorMessage,
                            trackingNumber,
                        } = CampaignUtils.formatData(orders);

                        return (
                            <CampaignItem
                                id={id}
                                key={id}
                                pid={pid}
                                name={name}
                                orders={history}
                                orderId={orderId}
                                latestStatus={status}
                                defaultValue={isEnrolled}
                                errorMessage={errorMessage}
                                trackingNumber={trackingNumber}
                                campaignStatus={campaignStatus}
                            />
                        );
                    }
                )
            )}
        </Card>
    );
};

CampaignCard.propTypes = {
    pid: PropTypes.string.isRequired,
};

export default CampaignCard;
