import {cnb} from 'cnbuilder';
import has from 'lodash/has';
import isUndefined from 'lodash/isUndefined';
import format from 'date-fns/format';
import css from './PotentialMatch.scss';

const PROPERTIES = {
    pid: 'PID',
    trackingId: 'Tracking UUID',
    firstName: 'First Name',
    lastName: 'Last Name',
    birthDate: 'DOB',
    zipCode: 'Zip Code',
    clientCode: 'Client Code',
    memberId: 'HPID',
    address: 'Address',
    state: 'State',
    city: 'City',
};

function formatBirthdate(date) {
    return date ? format(new Date(date), 'MM/dd/yyyy') : null;
}

function formatRow({data, isEven, compareTo}) {
    const hasAction = has(data, 'action');

    return Object.entries(PROPERTIES).reduce(
        (row, [key]) => {
            const cellValue = data[key] || null;
            const value =
                key === 'birthDate' ? formatBirthdate(cellValue) : cellValue;

            return {
                ...row,
                [key]: {
                    value,
                    hasError:
                        key === 'trackingId' || isUndefined(compareTo)
                            ? false
                            : cellValue?.toUpperCase() !==
                              compareTo?.[key]?.toUpperCase(),
                },
            };
        },
        {
            className: cnb(css.row, {
                [css.rowOdd]: !isEven,
                [css.rowEven]: isEven,
            }),
            action: {
                hasAction,
                ...(hasAction && {
                    parentPID: compareTo?.pid,
                    parentUUID: compareTo?.uuid,
                    onActionClick: data.action.onActionClick,
                }),
            },
        }
    );
}

const PotentialMatchUtils = {
    PROPERTIES,

    formatData: ({data, onActionClick}) => {
        return data.reduce((rows, record, index) => {
            const isEven = (index + 1) % 2 === 0;
            const {
                recruitableNotMatchedResponse: signupData,
                recruitableFuzzyMatchResponse: sourceData,
            } = record;

            return [
                ...rows,
                formatRow({isEven, data: signupData}),
                ...sourceData.map((item, sourceIndex) =>
                    formatRow({
                        isEven,
                        data: {...item, action: {onActionClick}},
                        compareTo: signupData,
                    })
                ),
            ];
        }, []);
    },
};

export default PotentialMatchUtils;
