import merge from 'lodash/merge';

const Config = {
    PLATFORM_API_URL: null, // 'https://bep.integration.livongo.com/',
    MICROSERVICE_API_URL: null, // 'https://usvc.integration.livongo.com/',
    MEMBER_ACCESS_TOKEN: null, // JWT to make API requests on behalf of a member
    API_URL: null,

    set(opts) {
        merge(this, opts);
    },

    ZIPCODE_SHORT_REGEX: /^[0-9]{5}$/,
    ZIPCODE_EXTENDED_REGEX: /^[0-9]{5}(?:-[0-9]{4})?$/,
    PHONE_REGEX: /^[(]?([0-9]{3})[)]?([-. ])?([0-9]{3})([-. ])?([0-9]{4})$/,

    GENDERS: [
        {id: 'Male', label: 'Male', value: 'Male'},
        {id: 'Female', label: 'Female', value: 'Female'},
    ],

    PHONE_TYPE: {
        mobile: 'MO',
        home: 'HO',
    },
};

export default Config;
