import format from 'date-fns/format';
import {APIUtils} from '@livongo/utilities/system/api';
import Config from 'common/config';

const PotentialMatchAPI = {
    fetchRecords: ({queryKey}) => {
        const [, params] = queryKey;
        const {count = 10, offset = 0, clientCodes = []} = params;

        return APIUtils.post(
            `${Config.API_URL}v1/recruitables/lookup/simple/standalone`,
            {
                count,
                offset,
                clientCodes,
            }
        ).then(
            ({
                data: {
                    recruitableMonthlyEligibilityPairs: data,
                    paginationMeta: pagination,
                },
            }) => ({data, pagination})
        );
    },

    fetchClients: () => {
        return APIUtils.get(
            `${Config.API_URL}v1/recruitables/lookup/clientCodes`,
            null,
            {data: null}
        ).then(({data}) => data);
    },

    match: record => {
        const {
            birthDate,
            action: {parentUUID: userUuid},
            trackingId: {value: trackingUuid},
            firstName: {value: matchedGivenName},
            lastName: {value: matchedFamilyName},
            clientCode: {value: matchedClientCode},
            zipCode: {value: matchedPostalCode},
        } = record;

        return APIUtils.patch(
            `${Config.API_URL}v1/recruitables/update/simple/standalone`,
            {
                userUuid,
                trackingUuid,
                matchedGivenName,
                matchedFamilyName,
                matchedPostalCode,
                matchedClientCode,
                matchedBirthDate: birthDate.value
                    ? format(new Date(birthDate.value), 'yyyy-MM-dd')
                    : '',
            }
        ).then(({data}) => data);
    },
};

export default PotentialMatchAPI;
