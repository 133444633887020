import PropTypes from 'prop-types';
import {cnb} from 'cnbuilder';
import IconAlertActive from '@livongo/pulse/icons/alert-active.svg';
import Card from '../Card';
import css from './ApiError.scss';

const ApiError = ({className, useCard}) => {
    const Element = useCard ? Card : 'div';

    return (
        <Element className={cnb(css.root, className)}>
            <IconAlertActive className={css.icon} />
            <div>
                <h2 className="heading-sm">Error</h2>
                <p>
                    There was an error loading the requested data. Please try
                    again. If the issue persists, please reach out on Slack in
                    the <strong>#help-galaxy</strong> channel.
                </p>
            </div>
        </Element>
    );
};

ApiError.propTypes = {
    className: PropTypes.string,
    useCard: PropTypes.bool,
};

ApiError.defaultProps = {
    useCard: false,
};

export default ApiError;
