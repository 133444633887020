import APIUtils from '@livongo/utilities/system/api';
import Config from 'common/config';

const SERVICE_NAME = 'service-athome-labs';

const CampaignAPI = {
    getCampaigns: ({queryKey}) => {
        const [, pid] = queryKey;

        if (!pid) {
            throw Error(
                'Member PID should be passed to the fetch campaigns API'
            );
        }

        return APIUtils.get(
            `${SERVICE_NAME}/users/me/campaigns`,
            {activeOnly: false},
            {
                headers: {
                    Authorization: `Bearer ${Config.MEMBER_ACCESS_TOKEN}`,
                },
            }
        ).then(({data}) => data);
    },

    enroll: ({queryKey}) => {
        const [, params] = queryKey;
        const {isEnrolling, campaignId, pid} = params;

        if (!campaignId || !pid) {
            throw Error(
                'Campaign ID and member PID should be passed to the enroll in/out of campaign API'
            );
        }

        return APIUtils.patch(
            `${SERVICE_NAME}/users/me/campaigns/${campaignId}`,
            {
                optIn: isEnrolling,
            },
            {
                headers: {
                    Authorization: `Bearer ${Config.MEMBER_ACCESS_TOKEN}`,
                },
            }
        );
    },
};

export default CampaignAPI;
