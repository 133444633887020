import APIUtils from '@livongo/utilities/system/api';
import Config from 'common/config';

const SERVICE_NAME = 'service-athome-labs';

const FulfillmentAPI = {
    reorderTestKit: ({queryKey}) => {
        const [, params] = queryKey;
        const {userId, campaignId} = params;

        return APIUtils.post(
            `${SERVICE_NAME}/users/labOrders`,
            {
                userId,
                campaignId,
            },
            {
                headers: {
                    Authorization: `Bearer ${Config.MEMBER_ACCESS_TOKEN}`,
                },
            }
        ).then(({data}) => data);
    },

    reorderPendingKit: ({queryKey}) => {
        const [, params] = queryKey;
        const {
            orderId,
            status,
            shippingInfo: {
                firstName: userFirstName,
                lastName: userLastName,
                gender,
                addressLine1,
                addressLine2,
                city,
                state,
                country,
                zipcode: postalCode,
            },
        } = params;

        return APIUtils.patch(
            `${SERVICE_NAME}/users/me/campaigns/orders`,
            {
                orderId,
                status,
                shippingInfo: {
                    userFirstName,
                    userLastName,
                    gender: gender === 'Male' ? 'M' : 'F',
                    addressLine1,
                    addressLine2,
                    city,
                    state,
                    country,
                    postalCode,
                },
            },
            {
                headers: {
                    Authorization: `Bearer ${Config.MEMBER_ACCESS_TOKEN}`,
                },
            }
        ).then(({data}) => data);
    },
};

export default FulfillmentAPI;
